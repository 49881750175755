define("ember-file-upload/system/parse-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseHTML;

  function parseHTML(string) {
    var tmp = document.implementation.createHTMLDocument('');
    tmp.body.innerHTML = string;
    return [tmp.body];
  }
});