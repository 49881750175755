define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5D+kSHZX",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"input\"],[11,\"id\",[20,\"for\"]],[11,\"accept\",[20,\"accept\"]],[11,\"capture\",[20,\"capture\"]],[11,\"multiple\",[20,\"multiple\"]],[11,\"disabled\",[20,\"disabled\"]],[11,\"onchange\",[26,\"action\",[[21,0,[]],\"change\"],[[\"value\"],[\"target.files\"]]]],[10,\"hidden\",\"\"],[10,\"type\",\"file\"],[8],[9],[13,1,[[22,[\"queue\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});