enifed("ember-views/lib/views/states/default", ["exports", "ember-debug"], function (exports, _emberDebug) {
  "use strict";

  exports.default = {
    // appendChild is only legal while rendering the buffer.
    appendChild: function () {
      throw new _emberDebug.Error("You can't use appendChild outside of the rendering process");
    },


    // Handle events from `Ember.EventDispatcher`
    handleEvent: function () {
      return true; // continue event propagation
    },
    rerender: function () {},
    destroy: function () {}
  };
});