define('ember-cli-cordova/mixins/controllers/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    nav: {
      title: {},
      leftButton: {},
      rightButton: {}
    },

    actions: {
      leftButton: function leftButton() {
        var leftAction = this.get('nav.leftButton.action');

        if (leftAction) {
          leftAction();
        }
      },

      rightButton: function rightButton() {
        var rightAction = this.get('nav.rightButton.action');

        if (rightAction) {
          rightAction();
        }
      },

      resetNavBar: function resetNavBar() {
        this.set('nav', {
          title: {},
          leftButton: {},
          rightButton: {}
        });
      }
    }
  });
});