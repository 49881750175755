(function () {

    'use-strict'
    window.idioma = {

        general:{

            navegacion:{

                lbl:{

                    label_ir_a_diagnostico_empresarial: {
                        valor:      'Diagn&oacute;stico Empresarial', /*Default: Diagn&oacute;stico Empresarial*/
                        descripcion:''
                    },

                    label_ir_a_noticias: {
                        valor:      'Noticias', /*Default: Noticias*/
                        descripcion:''
                    }

                }

            }

        },

        diagnostico_empresarial:{

            diagnostico_box:{

                lbl:{

                    label_titulo_invitacion_aplicacion:{
                        valor:      '¿Quieres conocer c&oacute;mo esta tu negocio?', /*Default: ¿Quieres conocer c&oacute;mo esta tu negocio?*/
                        descripcion:''
                    },

                    label_iniciar_aplicacion_evaluacion:{
                        valor:      'INICIAR EVALUACI&Oacute;N', /*Default: INICIAR EVALUACI&Oacute;N*/
                        descripcion:''
                    }

                }

            }

        },

        comunidades: {

            comunidad_especifica: {

                btn:{

                        btn_pregunta_a_un_experto: {
                            valor:      'Preguntale a un experto', /*Default: Preguntale a un experto*/
                            descripcion:''
                        }

                },

                lbl:{

                        label_titulo_columna_reciente:{
                            valor:      '', /*Default: Reciente*/
                            descripcion:''
                        },

                        label_diagnostico_box_titulo:{
                            valor:      'Diagn&oacute;sticos', /*Default: Diagn&oacute;sticos*/
                            descripcion:''
                        },

                        label_noticias_box_titulo:{
                            valor:      'Noticias', /*Default: Noticias*/
                            descripcion:''
                        },

                        label_titulo_cursos_disponibles:{
                            valor:      'Cursos disponibles', /*Default: Cursos de la comunidad*/
                            descripcion:''
                        }

                }

            }
        },

        noticias:{

            noticia_especifica:{

                btn:{

                    btn_ir_a_noticias_general: {
                        valor:      'Ir a Noticias', /*Default: Ir a noticias*/
                        descripcion:''
                    }

                },

                lbl: {

                    label_titulo_noticias:{
                        valor:      'Noticias', /*Default: Noticias*/
                        descripcion:''
                    }

                }

            }

        }
    };

})();

