enifed('ember-debug/lib/warn', ['exports', 'ember-environment', 'ember-debug/lib/deprecate', 'ember-debug/index', 'ember-debug/lib/handlers'], function (exports, _emberEnvironment, _deprecate, _index, _handlers) {
  'use strict';

  exports.missingOptionsDeprecation = exports.missingOptionsIdDeprecation = exports.registerHandler = undefined;


  var registerHandler = function () {};
  var warn = function () {};
  var missingOptionsDeprecation = void 0,
      missingOptionsIdDeprecation = void 0;

  /**
  @module @ember/debug
  */

  if (true) {
    /**
      Allows for runtime registration of handler functions that override the default warning behavior.
      Warnings are invoked by calls made to [@ember/debug/warn](https://emberjs.com/api/ember/release/classes/@ember%2Fdebug/methods/warn?anchor=warn).
      The following example demonstrates its usage by registering a handler that does nothing overriding Ember's
      default warning behavior.
       ```javascript
      import { registerWarnHandler } from '@ember/debug';
       // next is not called, so no warnings get the default behavior
      registerWarnHandler(() => {});
      ```
       The handler function takes the following arguments:
       <ul>
        <li> <code>message</code> - The message received from the warn call. </li>
        <li> <code>options</code> - An object passed in with the warn call containing additional information including:</li>
          <ul>
            <li> <code>id</code> - An id of the warning in the form of <code>package-name.specific-warning</code>.</li>
          </ul>
        <li> <code>next</code> - A function that calls into the previously registered handler.</li>
      </ul>
       @public
      @static
      @method registerWarnHandler
      @for @ember/debug
      @param handler {Function} A function to handle warnings.
      @since 2.1.0
    */
    exports.registerHandler = registerHandler = function registerHandler(handler) {
      (0, _handlers.registerHandler)('warn', handler);
    };

    registerHandler(function logWarning(message) {
      /* eslint-disable no-console */
      console.warn('WARNING: ' + message);
      if (console.trace) {
        console.trace();
      }
      /* eslint-enable no-console */
    });

    exports.missingOptionsDeprecation = missingOptionsDeprecation = 'When calling `warn` you ' + 'must provide an `options` hash as the third parameter.  ' + '`options` should include an `id` property.';
    exports.missingOptionsIdDeprecation = missingOptionsIdDeprecation = 'When calling `warn` you must provide `id` in options.';

    /**
      Display a warning with the provided message.
       * In a production build, this method is defined as an empty function (NOP).
      Uses of this method in Ember itself are stripped from the ember.prod.js build.
       @method warn
      @for @ember/debug
      @static
      @param {String} message A warning to display.
      @param {Boolean} test An optional boolean. If falsy, the warning
        will be displayed.
      @param {Object} options An object that can be used to pass a unique
        `id` for this warning.  The `id` can be used by Ember debugging tools
        to change the behavior (raise, log, or silence) for that specific warning.
        The `id` should be namespaced by dots, e.g. "ember-debug.feature-flag-with-features-stripped"
      @public
      @since 1.0.0
    */
    warn = function warn(message, test, options) {
      if (arguments.length === 2 && typeof test === 'object') {
        options = test;
        test = false;
      }

      if (_emberEnvironment.ENV._ENABLE_WARN_OPTIONS_SUPPORT !== true) {
        (0, _index.assert)(missingOptionsDeprecation, options);
        (0, _index.assert)(missingOptionsIdDeprecation, options && options.id);
      }

      if (!options && _emberEnvironment.ENV._ENABLE_WARN_OPTIONS_SUPPORT === true) {
        (0, _deprecate.default)(missingOptionsDeprecation, false, {
          id: 'ember-debug.warn-options-missing',
          until: '3.0.0',
          url: 'https://emberjs.com/deprecations/v2.x/#toc_ember-debug-function-options'
        });
      }

      if (options && !options.id && _emberEnvironment.ENV._ENABLE_WARN_OPTIONS_SUPPORT === true) {
        (0, _deprecate.default)(missingOptionsIdDeprecation, false, {
          id: 'ember-debug.warn-id-missing',
          until: '3.0.0',
          url: 'https://emberjs.com/deprecations/v2.x/#toc_ember-debug-function-options'
        });
      }

      (0, _handlers.invoke)('warn', message, test, options);
    };
  }

  exports.default = warn;
  exports.registerHandler = registerHandler;
  exports.missingOptionsIdDeprecation = missingOptionsIdDeprecation;
  exports.missingOptionsDeprecation = missingOptionsDeprecation;
});