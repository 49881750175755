enifed('ember-routing/lib/system/router_state', ['exports', 'ember-babel', 'ember-utils', 'ember-routing/lib/utils'], function (exports, _emberBabel, _emberUtils, _utils) {
  'use strict';

  var RouterState = function () {
    function RouterState() {
      var emberRouter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var routerJs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var routerJsState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      (0, _emberBabel.classCallCheck)(this, RouterState);

      this.emberRouter = emberRouter;
      this.routerJs = routerJs;
      this.routerJsState = routerJsState;
    }

    RouterState.prototype.isActiveIntent = function isActiveIntent(routeName, models, queryParams, queryParamsMustMatch) {
      var state = this.routerJsState;
      if (!this.routerJs.isActiveIntent(routeName, models, null, state)) {
        return false;
      }

      if (queryParamsMustMatch && Object.keys(queryParams).length > 0) {
        var visibleQueryParams = (0, _emberUtils.assign)({}, queryParams);

        this.emberRouter._prepareQueryParams(routeName, models, visibleQueryParams);
        return (0, _utils.shallowEqual)(visibleQueryParams, state.queryParams);
      }

      return true;
    };

    return RouterState;
  }();

  exports.default = RouterState;
});