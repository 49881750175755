enifed("@glimmer/wire-format", ["exports"], function (exports) {
    "use strict";

    var Opcodes;
    (function (Opcodes) {
        // Statements
        Opcodes[Opcodes["Text"] = 0] = "Text";
        Opcodes[Opcodes["Append"] = 1] = "Append";
        Opcodes[Opcodes["Comment"] = 2] = "Comment";
        Opcodes[Opcodes["Modifier"] = 3] = "Modifier";
        Opcodes[Opcodes["Block"] = 4] = "Block";
        Opcodes[Opcodes["Component"] = 5] = "Component";
        Opcodes[Opcodes["OpenElement"] = 6] = "OpenElement";
        Opcodes[Opcodes["OpenSplattedElement"] = 7] = "OpenSplattedElement";
        Opcodes[Opcodes["FlushElement"] = 8] = "FlushElement";
        Opcodes[Opcodes["CloseElement"] = 9] = "CloseElement";
        Opcodes[Opcodes["StaticAttr"] = 10] = "StaticAttr";
        Opcodes[Opcodes["DynamicAttr"] = 11] = "DynamicAttr";
        Opcodes[Opcodes["AttrSplat"] = 12] = "AttrSplat";
        Opcodes[Opcodes["Yield"] = 13] = "Yield";
        Opcodes[Opcodes["Partial"] = 14] = "Partial";
        Opcodes[Opcodes["DynamicArg"] = 15] = "DynamicArg";
        Opcodes[Opcodes["StaticArg"] = 16] = "StaticArg";
        Opcodes[Opcodes["TrustingAttr"] = 17] = "TrustingAttr";
        Opcodes[Opcodes["Debugger"] = 18] = "Debugger";
        Opcodes[Opcodes["ClientSideStatement"] = 19] = "ClientSideStatement";
        // Expressions
        Opcodes[Opcodes["Unknown"] = 20] = "Unknown";
        Opcodes[Opcodes["Get"] = 21] = "Get";
        Opcodes[Opcodes["MaybeLocal"] = 22] = "MaybeLocal";
        Opcodes[Opcodes["HasBlock"] = 23] = "HasBlock";
        Opcodes[Opcodes["HasBlockParams"] = 24] = "HasBlockParams";
        Opcodes[Opcodes["Undefined"] = 25] = "Undefined";
        Opcodes[Opcodes["Helper"] = 26] = "Helper";
        Opcodes[Opcodes["Concat"] = 27] = "Concat";
        Opcodes[Opcodes["ClientSideExpression"] = 28] = "ClientSideExpression";
    })(Opcodes || (exports.Ops = Opcodes = {}));

    function is(variant) {
        return function (value) {
            return Array.isArray(value) && value[0] === variant;
        };
    }
    // Statements
    var isModifier = is(Opcodes.Modifier);
    var isFlushElement = is(Opcodes.FlushElement);
    function isAttribute(val) {
        return val[0] === Opcodes.StaticAttr || val[0] === Opcodes.DynamicAttr || val[0] === Opcodes.TrustingAttr;
    }
    function isArgument(val) {
        return val[0] === Opcodes.StaticArg || val[0] === Opcodes.DynamicArg;
    }
    // Expressions
    var isGet = is(Opcodes.Get);
    var isMaybeLocal = is(Opcodes.MaybeLocal);

    exports.is = is;
    exports.isModifier = isModifier;
    exports.isFlushElement = isFlushElement;
    exports.isAttribute = isAttribute;
    exports.isArgument = isArgument;
    exports.isGet = isGet;
    exports.isMaybeLocal = isMaybeLocal;
    exports.Ops = Opcodes;
});