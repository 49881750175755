enifed('ember-views/lib/component_lookup', ['exports', 'ember-debug', 'ember-runtime'], function (exports, _emberDebug, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    componentFor: function (name, owner, options) {
      (true && !(~name.indexOf('-')) && (0, _emberDebug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen.', ~name.indexOf('-')));

      var fullName = 'component:' + name;
      return owner.factoryFor(fullName, options);
    },
    layoutFor: function (name, owner, options) {
      (true && !(~name.indexOf('-')) && (0, _emberDebug.assert)('You cannot use \'' + name + '\' as a component name. Component names must contain a hyphen.', ~name.indexOf('-')));


      var templateFullName = 'template:components/' + name;
      return owner.lookup(templateFullName, options);
    }
  });
});