enifed('ember-runtime/lib/ext/string', ['ember-environment', 'ember-runtime/lib/system/string'], function (_emberEnvironment, _string) {
  'use strict';

  /**
  @module @ember/string
  */

  var StringPrototype = String.prototype;

  if (_emberEnvironment.ENV.EXTEND_PROTOTYPES.String) {
    /**
      See [String.w](/api/ember/release/classes/String/methods/w?anchor=w).
       @method w
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'w', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.w)(this);
      }
    });

    /**
      See [String.loc](/api/ember/release/classes/String/methods/loc?anchor=loc).
       @method loc
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'loc', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        return (0, _string.loc)(this, args);
      }
    });

    /**
      See [String.camelize](/api/ember/release/classes/String/methods/camelize?anchor=camelize).
       @method camelize
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'camelize', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.camelize)(this);
      }
    });

    /**
      See [String.decamelize](/api/ember/release/classes/String/methods/decamelize?anchor=decamelize).
       @method decamelize
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'decamelize', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.decamelize)(this);
      }
    });

    /**
      See [String.dasherize](/api/ember/release/classes/String/methods/dasherize?anchor=dasherize).
       @method dasherize
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'dasherize', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.dasherize)(this);
      }
    });

    /**
      See [String.underscore](/api/ember/release/classes/String/methods/underscore?anchor=underscore).
       @method underscore
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'underscore', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.underscore)(this);
      }
    });

    /**
      See [String.classify](/api/ember/release/classes/String/methods/classify?anchor=classify).
       @method classify
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'classify', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.classify)(this);
      }
    });

    /**
      See [String.capitalize](/api/ember/release/classes/String/methods/capitalize?anchor=capitalize).
       @method capitalize
      @for @ember/string
      @static
      @private
    */
    Object.defineProperty(StringPrototype, 'capitalize', {
      configurable: true,
      enumerable: false,
      writeable: true,
      value: function () {
        return (0, _string.capitalize)(this);
      }
    });
  }
});