enifed('ember-application/index', ['exports', 'ember-application/lib/system/application', 'ember-application/lib/system/application-instance', 'ember-application/lib/system/resolver', 'ember-application/lib/system/engine', 'ember-application/lib/system/engine-instance', 'ember-application/lib/system/engine-parent'], function (exports, _application, _applicationInstance, _resolver, _engine, _engineInstance, _engineParent) {
  'use strict';

  Object.defineProperty(exports, 'Application', {
    enumerable: true,
    get: function () {
      return _application.default;
    }
  });
  Object.defineProperty(exports, 'ApplicationInstance', {
    enumerable: true,
    get: function () {
      return _applicationInstance.default;
    }
  });
  Object.defineProperty(exports, 'Resolver', {
    enumerable: true,
    get: function () {
      return _resolver.default;
    }
  });
  Object.defineProperty(exports, 'Engine', {
    enumerable: true,
    get: function () {
      return _engine.default;
    }
  });
  Object.defineProperty(exports, 'EngineInstance', {
    enumerable: true,
    get: function () {
      return _engineInstance.default;
    }
  });
  Object.defineProperty(exports, 'getEngineParent', {
    enumerable: true,
    get: function () {
      return _engineParent.getEngineParent;
    }
  });
  Object.defineProperty(exports, 'setEngineParent', {
    enumerable: true,
    get: function () {
      return _engineParent.setEngineParent;
    }
  });
});