define('ember-cli-cordova/mixins/cordova-events', ['exports', 'ember'], function (exports, _ember) {

  // include this mixin to define cordova event listeners with an onCordova object
  //
  // onCordova supports arrays, strings, and anonymous functions, e.g.:
  //
  // ```
  // export default MyEmberObject.extend({
  //   onCordova: {
  //     pause: ['pauseListening', 'disconnectPeripheral'],
  //     resume: 'resumeListening',
  //     volumeup: function() { console.log('a little bit louder now'); }
  //   }
  // });
  // ```
  exports['default'] = _ember['default'].Mixin.create({
    cordova: _ember['default'].inject.service(),

    subscribeToCordovaEvents: _ember['default'].on('init', function () {
      var cordova = this.get('cordova'),
          onCordova = this.get('onCordova');

      _ember['default'].keys(onCordova).forEach(function (key) {
        var func = _ember['default'].get(onCordova, key);

        // subscribe to events
        if (func instanceof Array) {
          func.forEach(function (fn) {
            if (this._validateIsFunction(fn)) {
              cordova.on(key, this, fn);
            }
          }, this);
        } else {
          if (this._validateIsFunction(func)) {
            cordova.on(key, this, func);
          }
        }
      }, this);
    }),

    _validateIsFunction: function _validateIsFunction(func) {
      var isFunction = false;

      if (func instanceof Function) {
        isFunction = true;
      } else if (typeof func === 'string') {
        var fn = this.get(func);

        isFunction = fn instanceof Function;
      }

      return isFunction;
    }
  });
});
/* jshint esnext:true */