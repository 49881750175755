enifed('ember-views/lib/views/states/destroying', ['exports', 'ember-utils', 'ember-debug', 'ember-views/lib/views/states/default'], function (exports, _emberUtils, _emberDebug, _default2) {
  'use strict';

  var destroying = Object.create(_default2.default);

  (0, _emberUtils.assign)(destroying, {
    appendChild: function () {
      throw new _emberDebug.Error("You can't call appendChild on a view being destroyed");
    },
    rerender: function () {
      throw new _emberDebug.Error("You can't call rerender on a view being destroyed");
    }
  });

  exports.default = destroying;
});